<template>
  <div class="GoodListCom">
      <!-- 搜索结果列表 -->
    <div class="searchResult_box">
      <div @click="goDetails(item)" v-for="item in list" :key="item.id" class="item_box">
        <el-image
          v-if="item.mealUrl"
          style="width: 258px; height: 258px"
          :src="item.mealUrl"
          fit="fill"></el-image>
          <div class="goodName">
            {{item.mealName}}
          </div>
          <div class="price_box">
            <template v-if="item.mealPrice">
              <span style="font-size: 14px;">￥</span>
              <span class="price">{{item.mealPrice}}</span>
            </template>
          </div>

          <div class="viewDetail">查看详情</div>
      </div>

    </div>
    <div class="pagination_box">
      <!-- 分页 -->
      <el-pagination
        v-if="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.pageIndex"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pagination.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodListCom',
  props: {
      list: {
          type: Array,
          default: function () {
              return []
          }
      },
      pagination: {
          type: Object,
          default: null
      }
  },
  data() {
    return {
    };
  },
  computed:{
    
  },
  watch:{
  },
  methods: {
    //   跳转详情
    goDetails(info) {
        const {mealID, type, isActive} = info;
        // 0 礼包 1商品
        // 缓存当前ID 刷新用到
        if(type){
          sessionStorage.setItem('welfareMall_goodId', mealID);
          // sessionStorage.setItem('vueRouter', JSON.stringify('/welfareProductDetails'));
          document.cookie = "vueRouter="+ '/welfareProductDetails';
          this.$router.push({
            name: 'WelfareProductDetails',
            params: {mealID}
          })
        }else{ 
          sessionStorage.setItem('welfareMall_mealId', mealID);
          // 判断该礼包是否有效
          sessionStorage.setItem('welfareMall_isActive', isActive);
          // sessionStorage.setItem('vueRouter', JSON.stringify('/welfareSetMealDetails'));
          document.cookie = "vueRouter="+ '/welfareSetMealDetails';
          this.$router.push({
            name: 'WelfareSetMealDetails',
            params: {mealID} 
          })
        }
        
    },
    // 页面条数发生改变
    handleSizeChange(val){
      this.$emit('handle-size-change', val)
    },
    //页面页码发生改变 
    handleCurrentChange(val){
      this.$emit('handle-current-change', val)

    }
  },
};
</script>

<style scoped lang="less">
.searchResult_box{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .item_box{
      width: 282px;
      height: 369px;
      border: 1px solid #E0E0E0;
      margin: 0 24px 24px 0;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      .goodName{
        display:-webkit-box;
        font-size: 14px;
        line-height: 24px;
        height: 43px;
        text-overflow: ellipsis;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        overflow:hidden;
      }
      .price_box{
        color: #F5222D;
        .price{
          font-size: 18px;
          font-weight: 600;
        }
      }
      .viewDetail{
        position: absolute;
        right: 12px;
        bottom: 12px;
        color: #0089cd;
        font-size: 14px;
      }

    }
    .item_box:nth-child(4n){
      margin-right: 0;
    }
    .item_box:hover{
        border: 1px solid #F5222D;
    }
  }
  .pagination_box{
    width: 1200px;
    margin: 20px auto;
    text-align: right;
  }
</style>
