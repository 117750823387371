<template>
  <div class="welfareBreadcrumb">
      <span class="left_icon" v-if="false"></span>
      <span> 当前位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item  
          v-for="item in itemBreadcrumb" 
          :key="item.to">
          <a @click="goPath(item.to)">{{item.name}}</a>
          
          </el-breadcrumb-item>
      </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'WelfareBreadcrumb',
  props: {
      itemBreadcrumb: {
          type: Array,
          default: function () {
                return []
          }
      }
  },
  created() {
  },
  data() {
    return {
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {
    // 点击跳转路由
      goPath(to){
        document.cookie = "vueRouter="+ to;
        this.$router.push(to);
      }
  },
};
</script>

<style  lang="less">
.welfareBreadcrumb{
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 24px !important;
  width: 1200px;
  margin: 0 auto;
  .left_icon{
    display: inline-block;
    width: 0px;
    height: 20px;
    border: 2px solid #0089CD;
    margin-right: 9px;
    border-radius: 4px;
  }
}
</style>
